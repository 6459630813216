import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputSection from '../../components/InputSection'

const stdInputProps = {
  type: 'number',
  size: 'small',
  variant: 'outlined',
}

const DebtCountdown = () => {
  const [startDate, setDate] = React.useState(new Date().getTime())
  const [dateNow, setDateNow] = React.useState(new Date().getTime())
  const [originalTimeLeft, setOriginalTimeLeft] = React.useState(0)
  const [netWorth, setNetWorth] = React.useState(50000)
  const [interest, setInterest] = React.useState(7)
  const [totalDebt, setTotalDebt] = React.useState(3)

  const [monthlyPayment, setMonthlyPayment] = React.useState(1500)
  const [monthlyPaymentText, setMonthlyPaymentText] = React.useState(1000)

  const timeSinceStart = (dateNow - startDate) / 1000

  const moneySinceStart =
    (timeSinceStart * netWorth * (interest / 100)) / (86400 * 365)
  const moneyPerDay = ((netWorth + moneySinceStart) * (interest / 100)) / 365

  React.useEffect(() => {
    setInterval(() => {
      setDateNow(new Date().getTime())
    }, 40)
  }, [])

  const daysPerMonth = 365 / 12

  const timeLeft =
    (totalDebt / monthlyPayment) * 86400 * daysPerMonth - timeSinceStart

  const initialCalculations = () => {
    let debtLeft = netWorth
    let month = 0
    let paid = 0

    while (debtLeft > 0 && month < 10000) {
      debtLeft += debtLeft * (interest / 1200)

      if (debtLeft < monthlyPayment) {
        paid += debtLeft
      } else {
        paid += monthlyPayment
      }

      month += 1
      debtLeft -= monthlyPayment
    }
    if (totalDebt !== paid) {
      setTotalDebt(paid)
    }
    return paid
  }

  React.useEffect(() => {
    initialCalculations()
  }, [totalDebt, netWorth, monthlyPayment, interest])

  if (originalTimeLeft === 0) {
    setOriginalTimeLeft(timeLeft)
  }

  function numberWithCommas(x) {
    if (isNaN(x)) {
      return 0
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function fillZeros(x) {
    if (isNaN(x)) {
      return 0
    }
    if (('' + x).length === 1) {
      return '0' + x
    }
    return x
  }

  const yearsLeft = Math.floor(timeLeft / (86400 * 365))
  const daysLeft = Math.floor(timeLeft / 86400) % 365
  const hoursLeft = Math.floor((timeLeft % 86400) / 3600)
  const minutesLeft = Math.floor((timeLeft % 3600) / 60)
  const secondsLeft = Math.floor(timeLeft % 60)

  const useStyles = makeStyles((theme) => ({
    number: {
      fontSize: '1.75em',
      margin: '0',
    },
    text: {
      fontSize: '1.25em',
      marginLeft: '0.625rem',
      marginRight: '1.25rem',
    },
  }))

  const CNumber = (props) => {
    const number = Number.parseFloat(props.number)
    return (
      <>
        <span className={classes.number}>
          ${numberWithCommas(Math.floor(number))}
        </span>
        <span className={classes.number} style={{ fontSize: '1em' }}>
          {('' + (number % 1).toFixed(5)).substring(1)}
        </span>
        <br></br>
        <br></br>
      </>
    )
  }

  const classes = useStyles()
  return (
    <Layout>
      <header className="App-header">
        <Helmet>
          <title>Not worth calculator</title>
          <meta name="description" content="Create a countdown for netWorth." />
        </Helmet>
        <Header
          links={['/', '/counter/net-worth/']}
          texts={['Counters', 'net worth counter']}
        />
        <h1>Net worth counter</h1>
        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '700px',
            marginBottom: '50px',
            textAlign: 'left',
          }}
        >
          <p>
            See how fast your net worth is growing. How much you increase each
            day and month.
          </p>
        </div>
        <div
          style={{
            display: 'flex', //flex
            flexDirection: 'row',
            maxWidth: '400px',
            paddingTop: '15px',
            boxShadow: '0 2px 8px rgba(0,0,0,.2)',
            background: 'white',
            width: 'inherit',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              flexFlow: 'wrap',
              justifyContent: 'center',
              maxWidth: '340px',
              display: 'inherit',
              paddingLeft: '48px',
              paddingRight: '48px',
            }}
          >
            <h2
              style={{
                marginBottom: '1.2rem',
                width: '100%',
              }}
            >
              Your data
            </h2>
            <InputSection>
              <p style={{ marginTop: '7px' }}>Net worth</p>
              <TextField
                {...stdInputProps}
                label=""
                onChange={(e) => setNetWorth(parseInt(e.target.value))}
                value={netWorth}
              />
            </InputSection>
            {false && (
              <InputSection>
                <p style={{ marginTop: '7px' }}>Monthly change</p>
                <TextField
                  {...stdInputProps}
                  label=""
                  onChange={(e) => {
                    const value = parseInt(e.target.value)
                    setMonthlyPayment(value)
                    if (value !== 0) {
                      setMonthlyPayment(value)
                      setMonthlyPaymentText(value)
                    } else {
                      setMonthlyPaymentText(value)
                    }
                    const timeLeft =
                      (netWorth / monthlyPayment) * 86400 * daysPerMonth -
                      timeSinceStart
                    setOriginalTimeLeft(timeLeft)
                  }}
                  value={monthlyPayment}
                />
              </InputSection>
            )}

            <InputSection>
              <p style={{ marginTop: '7px' }}>Expected yearly return (%)</p>
              <TextField
                {...stdInputProps}
                label=""
                onChange={(e) => {
                  setInterest(e.target.value)
                }}
                value={interest}
              />
            </InputSection>
          </div>
        </div>

        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '1200px',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          <br />
        </div>
        <div
          style={{
            marginTop: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '900px',
            marginBottom: '50px',
            textAlign: 'center',
          }}
        >
          <h2>Net worth</h2>

          <CNumber number={netWorth + moneySinceStart} />
          <h2>Per hour:</h2>
          <CNumber number={moneyPerDay / 24} />
          <h2>Per work hour (40/week):</h2>
          <CNumber number={((168 / 40) * moneyPerDay) / 24} />
          <h2>Per day:</h2>
          <CNumber number={moneyPerDay} />
          <h2>Per month:</h2>
          <CNumber number={moneyPerDay * 30.4375} />
          <h2>Per year</h2>
          <CNumber number={moneyPerDay * 365.25} />
        </div>
      </header>
      <span style={{ paddingBottom: '32px' }} />
    </Layout>
  )
}

export default DebtCountdown
